/* -----------Assets------------ */
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Spin from "antd/lib/spin";
import Switch from "antd/lib/switch";
import React from "react";
import { useDispatch } from "react-redux";
import { updateFicheImmoConfig } from "../../redux/Immobilier/config/config.slice";
/* -----------Selectors------------ */
/* -----------Styles------------ */
import { useEffect, useState } from "react";
import RichTextEditor from "../rich_text_editor/RichTextEditor";
import SelectFormComponent from "../select_input_form/antdselect.component";
import "./styles.scss";

const ImmoConfigFormComponent = ({ form, ficheData, addFormItem }) => {
  /* -----------Selectors------------ */
  const loader = false;
  /* -----------State------------ */
  /* -----------UseEffect------------ */

  /* -----------TypicalCentences------------ */
  const TE_TITLE_LABELS = {
		en: { label: "Titre", key: "DescriptionCourte_" },
		fr: { label: "Title", key: "DescriptionCourte_" },
	};
	const TE_DESCRIPTION_LABELS = {
		en: "Typical sentence",
		fr: "Phrases types",
	};

  const [typicalSentence, setTypicalSentence] = useState("");
	const [typicalSentenceTitle, setTypicalSentenceTitle] = useState("");
	const [language, setLanguage] = useState("fr");
  useEffect(() => {
		setTypicalSentence(
      ficheData[`data_str[Phrase_${language}]`] || ""
		);
		setTypicalSentenceTitle(
			ficheData[`data_str[Phrase_title]`] || ""
		);
	}, [ficheData, language]);

	const onTitleChange = (newSentenceTitle) => {
		setTypicalSentenceTitle(newSentenceTitle);
	};

	const onEditorContentChange = (newSentence) => {
		setTypicalSentence(newSentence);
	};

  useEffect(() => {
    const t = setTimeout(() => {
      dispatch(
        updateFicheImmoConfig({
          ...ficheData,
          [`data_str[Phrase_${language}]`]: typicalSentence,
          [`data_str[Phrase_title]`]: typicalSentenceTitle
        })
      )
    }, 400);
  
    return () => clearTimeout(t);
  }, [typicalSentence, typicalSentenceTitle, language]);

  /* -----------VARS------------ */
  const dispatch = useDispatch();
  /* -----------FCTS------------ */
  const handle_formChange = (e) => {
    dispatch(updateFicheImmoConfig(e))
  };
  /* -----------UI Vars------------ */
  return (
    <Spin spinning={loader} size="large">
      <div className="addImmoConfig_modal">
        <Form form={form} onValuesChange={handle_formChange}>
          {addFormItem.includes('Phrases') ?
            <RichTextEditor
              content={typicalSentence}
              title={typicalSentenceTitle}
              titleLabels={TE_TITLE_LABELS}
              textLabels={TE_DESCRIPTION_LABELS}
              onLanguageChange={setLanguage}
              onTextChange={onEditorContentChange}
              onTitleChange={onTitleChange} /> :
            <> 
              <Form.Item label="Libellé FR" name="data_str[Libelle_fr]">
                <Input size="small" />
              </Form.Item>
              <Form.Item label="Libellé EN" name="data_str[Libelle_en]">
                <Input size="small" />
              </Form.Item>
            </>
          }
          {addFormItem.includes('Famille') ?
            <Form.Item label="Menu Site" name="data_int[OnlineMenu]" valuePropName="checked">
              <Switch size="small" />
            </Form.Item>
            : null
          }
          {addFormItem.includes('Ruban') ?
            <>
              <SelectFormComponent
                formlabel="Position"
                name="data_str[TagSide]"
                dataOption={[
                  { label: "En haut à gauche", value: "ribbon-top-left" },
                  { label: "En haut à droite", value: "ribbon-top-right" },
                  { label: "En bas à gauche", value: "ribbon-bottom-left" },
                  { label: "En bas à droite", value: "ribbon-bottom-right" }
                ]}
                value="value"
                label="label"
                clearable={true} />
              <Form.Item label="Couleur du fond" name="data_str[BgColor]">
                <input type="color" />
              </Form.Item>
              <Form.Item label="Couleur du texte" name="data_str[TextColor]">
                <input type="color" />
              </Form.Item>
            </>
            : null
          }
        </Form>
      </div>
    </Spin>
  );
};

export default ImmoConfigFormComponent;
