import axios from "axios";
import API_ENDPOINT from "../../../api/api_endpoint";
import { deleteApi, getApi, postApi, putApi } from "../../../api/api_methods";
import { ObjetToArr } from "../../../assets/functions";
import { customerror, customsuccess } from "../../../components/icon_notification/customNotifications";
import { environment } from "../../../environment";
import { dataGridLoader, ficheImmoPropLoader, loadDataGrid, loadImmoPropCols, loadImmoPropFiche, loadproprioInf, loadPropSuivi } from "./prop.slice";




export function LoadDataGridAPI(payload, hidemsgSccs) {
    return async (dispatch) => {
        dispatch(dataGridLoader(true));
        getApi(API_ENDPOINT.LOAD_IMMO_PROP, payload)
            .then((res) => {
                let response = res.data ? res.data : [];
                dispatch(dataGridLoader(false));
                dispatch(loadDataGrid(ObjetToArr(response)));
                if (!hidemsgSccs)
                    customsuccess('Propriétés chargés avec succès!  ', "")
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            });
    };
}
export function LoadImmoPropColsAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_COLUMNS, payload)
            .then((res) => {
                let response = Object.values(res.data)[0];
                dispatch(loadImmoPropCols(response))
            })
            .catch((err) => {
                customerror("Erreur de chargement des colonnes", "");
            })
    };
}
export function SaveImmoPropColsAPI(payload) {
    return async (dispatch) => {
        postApi(API_ENDPOINT.LOAD_COLUMNS + "/?slug=properties", payload)
            .then((res) => {
                dispatch(LoadDataGridAPI({}, true))
                dispatch(loadImmoPropCols(Object.values(res.data)[0]))
                customsuccess("Changement effectué avec succès")
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement des données", "");
            })
    };
}
export function LoadImmoPropFicheAPI(payload, notShowScss) {
    return async (dispatch) => {
        dispatch(ficheImmoPropLoader(true));
        getApi(API_ENDPOINT.LOAD_IMMO_PROP + "/" + payload)
            .then((res) => {
                let response = Object.values(res.data)[0];
                dispatch(loadImmoPropFiche(response))
                if (!notShowScss)
                    customsuccess('Fiche chargée avec succès', "")
                dispatch(ficheImmoPropLoader(false));
            })
            .catch((err) => {
                customerror("Erreur de chargement des données", "");
            });
    };
}
export function LoadProprioInfAPI(payload) {
    return async (dispatch) => {
        dispatch(ficheImmoPropLoader(true))
        getApi(API_ENDPOINT.LOAD_DATA_GRID_CONTACT + "/" + payload)
            .then((res) => {
                dispatch(ficheImmoPropLoader(false))
                dispatch(loadproprioInf(res.data[payload]))
            })
            .catch((err) => {
                customerror("Erreur de chargement des données du propriétaire", "");
            })
    };
}
export function LoadPropSuiviAPI(payload) {
    return async (dispatch) => {
        getApi(API_ENDPOINT.LOAD_SUIVI, payload)
            .then((res) => {
                let response = res.data ? res.data : []
                dispatch(loadPropSuivi(response))
            })
            .catch((err) => {
                customerror("Erreur de chargement de la liste du suivi", "");
            });
    };
}
export function SavePropSuiviAPI(payload, payload2, setSuiviValue) {
    return async (dispatch) => {
        dispatch(ficheImmoPropLoader(true));
        postApi(API_ENDPOINT.LOAD_SUIVI, payload)
            .then((res) => {
                dispatch(LoadPropSuiviAPI(payload2))
                setSuiviValue("")
                dispatch(ficheImmoPropLoader(false));
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}
export function updateImmoAPI(payload, close_clearDrawer) {
    return async (dispatch) => {
        putApi(API_ENDPOINT.LOAD_IMMO_PROP, payload)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                close_clearDrawer();
                dispatch(LoadDataGridAPI({}, true));
            })
            .catch((err) => {
                dispatch(dataGridLoader(false));
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}
export function addImmoAPI(payload, close_clearDrawer) {
    return async (dispatch) => {
        postApi(API_ENDPOINT.LOAD_IMMO_PROP, payload)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                close_clearDrawer();
                dispatch(LoadDataGridAPI({}, true));
            })
            .catch((err) => {
                dispatch(dataGridLoader(false));
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}
export function deleteImmoAPI(payload, close_clearDrawer) {
    return async (dispatch) => {
        deleteApi(API_ENDPOINT.LOAD_IMMO_PROP + "/" + payload)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                close_clearDrawer()
                dispatch(LoadDataGridAPI({}, true));
            })
            .catch((err) => {
                customerror("Erreur de suppression des données", "");
            });
    };
}
export function addImgImmoAPI(formData, payload2, callback) {
    return async (dispatch) => {
        axios.post(environment.BASE_URL_API + "/" + API_ENDPOINT.MEDIAS + "?action=upload", formData)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                callback()
                dispatch(LoadImmoPropFicheAPI(payload2, true))
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}
export function updateImgImmoAPI(payload, payload2, callback) {
    return async (dispatch) => {
        putApi(API_ENDPOINT.MEDIAS + "?action=upload", payload)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                callback()
                dispatch(LoadImmoPropFicheAPI(payload2, true))
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement des données", "");
            });
    };
}
export function deleteImgImmoAPI(payload, payload2) {
    return async (dispatch) => {
        deleteApi(API_ENDPOINT.MEDIAS + "/" + payload)
            .then((res) => {
                customsuccess('Opération terminée avec succès!  ', "")
                dispatch(LoadImmoPropFicheAPI(payload2, true))
            })
            .catch((err) => {
                customerror("Erreur de suppression des données", "");
            });
    };
}
