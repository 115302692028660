export const userColsSelector = (state) => state.immoProp.userCols;
export const allColsSelector = (state) => state.immoProp.allCols;
export const selectedColsSelector = (state) => state.immoProp.selectedCols;
export const dataGridSelector = (state) => state.immoProp.dataGrid;
export const dataGridLoaderSelector = (state) => state.immoProp.dataGridLoader;
export const immoPropSelectedRowSelector = (state) => state.immoProp.selectedImmoPropRow;
export const ImmoPropFormSelector = (state) => state.immoProp.ficheImmoPropForm;
export const ImmoPropFormLoaderSelector = (state) => state.immoProp.ficheImmoPropLoader;
export const ImmoPropSuiviSelector = (state) => state.immoProp.propSuivi;
export const ImmoPropFormPicsSelector = (state) => state.immoProp.ficheImmoPropImages;
export const ImmoPropImgToUpdateSelector = (state) => state.immoProp.ficheImmoImageToUpdate;