import { getApi, putApi, postApi, deleteApi } from "../../../api/api_methods";
import { customerror, customsuccess } from "../../../components/icon_notification/customNotifications";
import { dataGridLoader, ficheConfigImmoLoader, initDatagrid, loadDataGrid, loadFicheConfigImmo } from "./config.slice";


export function LoadImmoConfigAPI(url, hideSccs, isFiche) {
    return async (dispatch) => {
        dispatch(initDatagrid({}))
        if (!isFiche) {
            dispatch(dataGridLoader(true))
        } else {
            dispatch(ficheConfigImmoLoader(true))
        }
        getApi(url, {})
            .then((res) => {
                let response = res.data ? res.data : []
                if (!isFiche) {
                    dispatch(dataGridLoader(false));
                    dispatch(loadDataGrid(response));
                    if (!hideSccs) customsuccess('Données chargées avec succès!', "");
                } else {
                    dispatch(ficheConfigImmoLoader(false));
                    dispatch(loadFicheConfigImmo(res.data));
                    customsuccess('Fiche chargée avec succès!', "");
                }
            })
            .catch((err) => {
                dispatch(dataGridLoader(false));
                dispatch(ficheConfigImmoLoader(false));
                customerror("Erreur de chargement des données", "");
            });
    };
}

export function updateConfigImmoAPI(url, payload, closeClearFiche, refresh_grid) {
    return async (dispatch) => {
        dispatch(ficheConfigImmoLoader(true));
        putApi(url, payload)
            .then((res) => {
                closeClearFiche();
                dispatch(ficheConfigImmoLoader(false));
                customsuccess("L'enregistrement a été correctement effectué", "");
                refresh_grid(true, false);
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement des données", "");
                dispatch(ficheConfigImmoLoader(false));
            });
    };
}
export function addConfigImmoAPI(url, payload, closeClearFiche, refresh_grid) {
    return async (dispatch) => {
        dispatch(ficheConfigImmoLoader(true));
        postApi(url, payload)
            .then((res) => {
                closeClearFiche();
                dispatch(ficheConfigImmoLoader(false));
                customsuccess("L'enregistrement a été correctement effectué", "");
                refresh_grid(true, false);
            })
            .catch((err) => {
                customerror("Erreur d'enregistrement des données", "");
                dispatch(ficheConfigImmoLoader(false));
            });
    };
}
export function deleteConfigImmoAPI(url, callback) {
    return async (dispatch) => {
        deleteApi(url)
            .then((res) => {
                callback()
                customsuccess("La suppresion a été correctement  effectuée", "");
            })
            .catch((err) => {
                customerror("Erreur de suppresion des données", "");
            });
    };
}