import { createSlice } from "@reduxjs/toolkit";
import { deleteFromFicheUtil, loadDataGridUtil, loadImmoPropColsUtil, loadImmoPropFicheUtil, loadImmoPropImgsUtil, loadproprioInfUtil, updateChoosedImgUtil, updateFicheUtil } from "./prop.util";
import _ from "lodash";

const initialState = {
    userCols: [],
    allCols: [],
    selectedCols: [],
    dataGrid: [],
    propSuivi: [],
    ficheImmoPropImages: [],
    dataGridLoader: false,
    selectedImmoPropRow: {},
    ficheImmoProp: {},
    ficheImmoPropForm: { 'data_int[IDPays]': 250 },
    ficheImmoPropLoader: false,
    ficheImmoImageToUpdate: null
};

const immoProp = createSlice({
    name: "immoProp",
    initialState,
    reducers: {
        loadDataGrid: (state, { payload }) => {
            state.dataGrid = loadDataGridUtil(payload);
        },
        loadImmoPropCols: (state, { payload }) => {
            let result = loadImmoPropColsUtil(payload);
            state.userCols = result?.userCols;
            state.allCols = result?.allCols;
        },
        setImmoPropSelectedCols: (state, { payload }) => {
            state.selectedCols = payload;
        },
        setDataGrid: (state, { payload }) => {
            state.dataGrid = payload;
        },
        dataGridLoader: (state, { payload }) => {
            state.dataGridLoader = payload;
        },
        setSlectedContactRow: (state, { payload }) => {
            state.selectedImmoPropRow = payload;
        },
        loadImmoPropFiche: (state, { payload }) => {
            let clonedProps = _.cloneDeep(state.dataGrid);
            state.ficheImmoProp = payload;
            state.ficheImmoPropImages = loadImmoPropImgsUtil(payload)
            state.ficheImmoPropForm = loadImmoPropFicheUtil(payload, clonedProps);
        },
        ficheImmoPropLoader: (state, { payload }) => {
            state.ficheImmoPropLoader = payload;
        },
        loadproprioInf: (state, { payload }) => {
            let clonedFiche = _.cloneDeep(state.ficheImmoPropForm);
            state.ficheImmoPropForm = loadproprioInfUtil(clonedFiche, payload);
        },
        updateFiche: (state, { payload }) => {
            let clonedFiche = _.cloneDeep(state.ficheImmoPropForm);
            state.ficheImmoPropForm = updateFicheUtil(clonedFiche, payload);
        },
        deleteFromFiche: (state, { payload }) => {
            let clonedFiche = _.cloneDeep(state.ficheImmoPropForm);
            state.ficheImmoPropForm = deleteFromFicheUtil(clonedFiche, payload);
        },
        initFicheImmoProp: (state, { payload }) => {
            state.ficheImmoProp = initialState.ficheImmoProp;
            state.ficheImmoPropForm = initialState.ficheImmoPropForm;
            state.ficheImmoPropImages = initialState.ficheImmoPropImages;
            state.propSuivi = initialState.propSuivi
        },
        loadPropSuivi: (state, { payload }) => {
            state.propSuivi = payload;
        },
        loadFicheToUpdate: (state, { payload }) => {
            state.ficheImmoImageToUpdate = payload;
        },
        updateChoosedImg: (state, { payload }) => {
            let clonedFiche = _.cloneDeep(state.ficheImmoImageToUpdate);
            state.ficheImmoImageToUpdate = updateChoosedImgUtil(clonedFiche, payload);
        },
        clearImageModal: (state, { payload }) => {
            state.ficheImmoImageToUpdate = initialState.ficheImmoImageToUpdate
        },
    },
});

export const { loadDataGrid, loadImmoPropCols, setImmoPropSelectedCols, setDataGrid, dataGridLoader, setSlectedContactRow, loadImmoPropFiche, ficheImmoPropLoader, loadproprioInf, updateFiche, deleteFromFiche, initFicheImmoProp, loadPropSuivi, loadFicheToUpdate, updateChoosedImg, clearImageModal } = immoProp.actions;

export default immoProp.reducer;
