/* -----------Assets------------ */
import React from "react";

/* -----------Style Sheet------------ */
import { ToolbarButtonStyle } from "./toolbar_button.styles.jsx";

const ToolbarButtonComponent = ({
  icon,
  disabled,
  text,
  onclick,
  className,
  title
}) => {
  return (
    <ToolbarButtonStyle
      title={title}
      disabled={disabled}
      onClick={onclick}
      className={`k-button k-button-sm k-button-rectangle k-button-solid ${className} k-rounded-md`}
    >
      <i className={`bi bi-${icon}`}></i>{text}
    </ToolbarButtonStyle>
  );
};

export default ToolbarButtonComponent;
